import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { graphql } from "gatsby";
import Section from "../../components/Section"
import Col from 'react-bootstrap/Col';
import { getFeaturedItem, getFeaturedItems } from "../../services/utility"
import Teaser from "../../components/Teaser";
import Feature from "../../components/Feature";
import Headline from "../../components/Headline"

export default class MinistryEvents extends React.Component {
  render() {
    let top = getFeaturedItem(this.props.data.top);
    let recent = getFeaturedItems(this.props.data.recent);
    let bottom = getFeaturedItem(this.props.data.bottom);
    return ( 
      <Layout>
        <Seo title="Events" />
        {top && <Section className="primary" useContainer={false}>
          <Feature content={top} centerVertically={true} />
        </Section> } 
        {recent && <Section className="white" useCol={false}>
          <Col xs={12}><Headline>Recent</Headline></Col>
          {recent.map((minEvent) => {
            return (
              <Col md={4} key={"event-" + minEvent.slug}>
                <Teaser content={minEvent} />
              </Col>
            );
          })}
        </Section> }
        {bottom && <Section className="dark" useContainer={false}>
          <Feature content={bottom} centerVertically={true} />
        </Section> } 
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    top: allContentfulFeatured(filter: {siteSection: {eq: "Events - Top"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    recent: allContentfulFeatured(filter: {siteSection: {eq: "Events - Recent"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    bottom: allContentfulFeatured(filter: {siteSection: {eq: "Events - Bottom"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
  }
`